import React from 'react';
import Layout from '../components/Layout';
import { Link } from 'react-router-dom';

import behindItem1 from '../assets/images/behind/1.png';
import behindItem2 from '../assets/images/behind/2.png';
import behindItem3 from '../assets/images/behind/3.png';
import behindItem4 from '../assets/images/behind/4.png';
import behindItem5 from '../assets/images/behind/5.png';
import behindItem6 from '../assets/images/behind/6.png';
import behindItem7 from '../assets/images/behind/7.png';
import behindItem8 from '../assets/images/behind/8.png';
import behindItem9 from '../assets/images/behind/9.png';
import behindItem10 from '../assets/images/behind/10.png';
import behindItem11 from '../assets/images/behind/11.png';
import behindItem12 from '../assets/images/behind/12.png';

let behindItems = [
  behindItem1,
  behindItem2,
  behindItem3,
  behindItem4,
  behindItem5,
  behindItem6,
  behindItem7,
  behindItem8,
  behindItem9,
  behindItem10,
  behindItem11,
  behindItem12,
];

const Behind = () => {
  return (
    <Layout>
      <section className='behind' id='behind'>
        <h2 className='title'>IN SPATELE SCENEI</h2>
        <div className='content'>
          {behindItems.map((behindItem, i) => (
            <img src={`${behindItem}`} alt='Luna Sculptorilor Romani'></img>
          ))}
        </div>
        <Link to='/main'> Înapoi </Link>
      </section>
    </Layout>
  );
};

export default Behind;
