import React from 'react';
import ReactDOM from 'react-dom';
import closeIcon from '../assets/icons/closeicon.png';


const Backdrop = (props) => {
  return <div className='backdrop' onClick={props.onCloseMenu} />;
};

const MenuOverlay = (props) => {
  return (
    <React.Fragment>
      <button className='close-btn' onClick={props.onCloseMenu} ><img
      className='menu-icon'
        src={closeIcon}
        alt='closeicon' 
      /></button>
      <div className='menu-modal'>
        <ul className='menu-list'>
          <a href='/main#about' onClick={props.onCloseMenu} > Despre </a>
          <a href='/main#event_land' onClick={props.onCloseMenu}> Evenimente </a>
          <a href='main#behind_land' onClick={props.onCloseMenu}> In spatele scenei </a>
          <a href='/main#archive_land' onClick={props.onCloseMenu}> Arhiva </a>
          <a href='/main#team' onClick={props.onCloseMenu}> Organizatori </a>
          <a href='/main#team' onClick={props.onCloseMenu}> Parteneri </a>
          <a href='/main#map' onClick={props.onCloseMenu}> Harta </a>
          <a href='/main#contact' onClick={props.onCloseMenu}> Contact </a>

          {/* <Link to='/main#about' onClick={props.onCloseMenu} > Despre </Link>
          <Link to='/main#events'> Evenimente </Link>
          <Link to='/main#behind'> In spatele scenei </Link>
          <Link to='/main#archive'> Arhiva </Link>
          <Link to='/main#team'> Echipa </Link>
          <Link to='/main#map'> Harta </Link>
          <Link to='/main#contact'> Contact </Link> */}
        </ul>
      </div>
    </React.Fragment>
  );
};

const MenuModal = (props) => {
  return (
    <React.Fragment>
      {ReactDOM.createPortal(
        <Backdrop onCloseMenu={props.onCloseMenu} />,
        document.getElementById('backdrop-root'),
      )}
      {ReactDOM.createPortal(
        <MenuOverlay onCloseMenu={props.onCloseMenu}></MenuOverlay>,
        document.getElementById('overlay-root'),
      )}
    </React.Fragment>
  );
};

export default MenuModal;
