import React from 'react';
import insta from '../assets/icons/insta_icon.png';
import fb from '../assets/icons/facebook_icon.png';
import arrow from '../assets/icons/arroworange2.png';

const Contact = () => {
  return (
    <section className='contact' id='contact'>
      <h2 className='title'>LUNA SCULPTORILOR ROMÂNI</h2>
      <p><a href="lsr.ateneu@yahoo.com">lsr.ateneu@yahoo.com</a></p>      
      <div className='socials'>
        <img src={insta}/>
        <img src={fb}/>
      </div>
        <img className='arrow' src={arrow}/>
    </section>
  );
};

export default Contact;
