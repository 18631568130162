import React from 'react';
import { Link } from 'react-router-dom';


import archive1 from '../assets/images/archives/bogdan.png';
import archive2 from '../assets/images/archives/bolocan.png';
import archive3 from '../assets/images/archives/bostan.png';
import archive4 from '../assets/images/archives/campeanu.png';
import archive5 from '../assets/images/archives/covataru.png';
import archive6 from '../assets/images/archives/crenganis.png';
import archive7 from '../assets/images/archives/drinceanu.png';
import archive8 from '../assets/images/archives/dumitras.png';
import archive9 from '../assets/images/archives/fainis.png';
import archive10 from '../assets/images/archives/gtanase.png';
import archive11 from '../assets/images/archives/iatesen.png';
import archive12 from '../assets/images/archives/istefanescu.png';
import archive13 from '../assets/images/archives/itanase.png';
import archive14 from '../assets/images/archives/kocsis.png';
import archive15 from '../assets/images/archives/lapusan.png';
import archive16 from '../assets/images/archives/magda.png';
import archive17 from '../assets/images/archives/mogosanu.png';
import archive18 from '../assets/images/archives/negara.png';
import archive19 from '../assets/images/archives/patrascanu.png';
import archive20 from '../assets/images/archives/popa.png';
import archive21 from '../assets/images/archives/ranga.png';
import archive22 from '../assets/images/archives/rata.png';
import archive23 from '../assets/images/archives/smau.png';
import archive24 from '../assets/images/archives/stefanescu.png';
import archive25 from '../assets/images/archives/ungureanu.png';
import archive26 from '../assets/images/archives/vartosu.png';
import archive27 from '../assets/images/archives/verdianu.png';
import archive28 from '../assets/images/archives/zderciuc.png';

let archives = [
  archive1,
  archive2,
  archive3,
  archive4,
  archive5,
  archive6,
  archive7,
  archive8,
  archive9,
  archive10,
  archive11,
  archive12,
  archive13,
  archive14,
  archive15,
  archive16,
  archive17,
  archive18,
  archive19,
  archive20,
  archive21,
  archive22,
  archive23,
  archive24,
  archive25,
  archive26,
  archive27,
  archive28,
];
const ArchiveComponent = () => {
  let landArchives = archives
    .sort(() => Math.random() - Math.random())
    .slice(0, 6);
  return (
    <section className='archive-land' id='archive_land'>
      <h2 className='title'>ARHIVA</h2>
      <div className='content'>
        {landArchives.map((archive, i) => (
          <img src={`${archive}`} alt='Luna Sculptorilor Romani'></img>
          
          /* <div
            className='archiveland'
            style={{ backgroundImage: `url('${archive}')` }}
            key={i}
          ></div> */
        ))}
      </div>
      <Link to='/archive'> Vezi mai multe </Link>

    </section>
  );
};

export default ArchiveComponent;
