import React from 'react';
import logo from '../assets/icons/Logolsr.png';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import menuIcon from '../assets/icons/menuicon.png';
import MenuModal from './MenuModal';

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const showMenuHandler = (event) => {
    setShowMenu(true);
  };
  const closeMenuHandler = () => {
    setShowMenu(false);
  };
  return (
    <div className='header'>
      <Link to='/'>
        <img 
        className='logo'
          src={logo}
          alt='Luna Sculptorilor Romani'
        />
      </Link>

      <img
      className='menu-icon'
        src={menuIcon}
        alt='Luna Sculptorilor Romani Menu'        
        onClick={showMenuHandler}
      />
      {showMenu && <MenuModal onCloseMenu={closeMenuHandler} />}
    </div>
  );
};

export default Header;
