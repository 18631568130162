import React from 'react';
import { Routes, BrowserRouter } from 'react-router-dom';
import { mainRoutes } from '../src/routes';
import './App.scss';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>{mainRoutes}</Routes>
    </BrowserRouter>
  );
}

export default App;
