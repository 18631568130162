import React from 'react';
import { Link } from 'react-router-dom';


import event5 from '../assets/images/events/Artboard 1 copy 5.png';
import event6 from '../assets/images/events/Artboard 1 copy 6.png';
import event7 from '../assets/images/events/Artboard 1 copy 7.png';
import event8 from '../assets/images/events/Artboard 1 copy 8.png';
import event9 from '../assets/images/events/Artboard 1 copy 9.png';
import event10 from '../assets/images/events/Artboard 1 copy 10.png';
import event11 from '../assets/images/events/Artboard 1 copy 11.png';
import event12 from '../assets/images/events/Artboard 1 copy 12.png';
import event13 from '../assets/images/events/Artboard 1 copy 13.png';
import event14 from '../assets/images/events/Artboard 1 copy 14.png';
import event15 from '../assets/images/events/Artboard 1 copy 15.png';
import event16 from '../assets/images/events/Artboard 1 copy 16.png';
import event17 from '../assets/images/events/Artboard 1 copy 17.png';
import event18 from '../assets/images/events/Artboard 1 copy 18.png';
import event19 from '../assets/images/events/Artboard 1 copy 19.png';
import event20 from '../assets/images/events/Artboard 1 copy 20.png';
import event21 from '../assets/images/events/Artboard 1 copy 21.png';
import event22 from '../assets/images/events/Artboard 1 copy 22.png';
import event23 from '../assets/images/events/Artboard 1 copy 23.png';
import event24 from '../assets/images/events/Artboard 1 copy 24.png';
import event36 from '../assets/images/events/Artboard 1 copy 36.png';

let events = [
  event5,
  event6,
  event7,
  event8,
  event9,
  event10,
  event11,
  event12,
  event13,
  event14,
  event15,
  event16,
  event17,
  event18,
  event19,
  event20,
  event21,
  event22,
  event23,
  event24,
  event36,
];
const EventsComponent = () => {
  let landEvents = events
  .sort(() => Math.random() - Math.random())
  .slice(0, 6);
  return (
    <section className='event-land' id='event_land'>
      <h2 className='title'>EVENIMENTE</h2>
      <div className='content'>
        {landEvents.map((event, i) => (
          <img src={`${event}`} alt='Luna Sculptorilor Romani'></img>
          
          /* <div
            className='eventland'
            style={{ backgroundImage: `url('${event}')` }}
            key={i}
          >
          </div> */
        ))}
      </div>
      <Link to='/events'> Vezi mai multe </Link>
    </section>
  );
};

export default EventsComponent;
