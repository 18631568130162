import React from 'react';
import Layout from '../components/Layout';
import artistwork from '../assets/images/FlorinMagda-37.jpg';
import About from './About';
import EventsComponent from '../components/EventsComponent';
import ArchiveComponent from '../components/ArchiveComponent';
import Team from './Team';
import Map from './Map';
import Contact from './Contact';
import BehindComponent from '../components/BehindComponent';


const Main = () => {
  return (
    <Layout>
    <section className='main' id='main'>
      <img src={artistwork} alt='Florin Magda'/>
    </section> 
    <About />
    <EventsComponent/>
    <BehindComponent/>
    <ArchiveComponent/>
    <Team/>
    <Map/>
    <Contact/>
  </Layout>
  );
};

export default Main;
