import React from 'react';
import team from '../assets/images/team.png';

const Team = () => {
  return (
    <section className='team' id='team'>
    <div className='content'>
        
            <img src={`${team}`} alt='Luna Sculptorilor Romani'></img>
         
        </div>
    </section>
  );
};

export default Team;
