import React from 'react';
import {Route} from "react-router-dom";
import About from "./pages/About";
import Archive from "./pages/Archive";
import Behind from "./pages/Behind";
import Contact from "./pages/Contact";
import Events from "./pages/Events";
import Home from "./pages/Home";
import Map from "./pages/Map";
import Team from "./pages/Team";
import Main from "./pages/Main";


export const mainRoutes = [
    <Route path='/' element={<Home />} />,
    <Route path='/main' element={<Main />} />,
    <Route path="/about" element={<About/>}/>,
    <Route path="/archive" element={<Archive/>}/>,
    <Route path="/behind" element={<Behind/>}/>,
    <Route path="/contact" element={<Contact/>}/>,
    <Route path="/events" element={<Events/>}/>,
    <Route path="/map" element={<Map/>}/>,
    <Route path="/team" element={<Team/>}/>,
];

 