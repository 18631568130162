import React, { useState } from 'react';
import {
  GoogleMap,
  InfoWindow,
  useLoadScript,
  Marker,
} from '@react-google-maps/api';

import icon1 from '../assets/icons_map/1.png';
import icon2 from '../assets/icons_map/2.png';
import icon3 from '../assets/icons_map/3.png';
import icon4 from '../assets/icons_map/4.png';
import icon5 from '../assets/icons_map/5.png';
import icon6 from '../assets/icons_map/6.png';
import icon7 from '../assets/icons_map/7.png';
import icon8 from '../assets/icons_map/8.png';
import icon9 from '../assets/icons_map/9.png';
import icon10 from '../assets/icons_map/10.png';
import icon11 from '../assets/icons_map/11.png';
import icon12 from '../assets/icons_map/12.png';
import icon13 from '../assets/icons_map/13.png';
import icon14 from '../assets/icons_map/14.png';
import icon15 from '../assets/icons_map/15.png';
import icon16 from '../assets/icons_map/16.png';
import icon17 from '../assets/icons_map/17.png';
import icon18 from '../assets/icons_map/18.png';
import icon19 from '../assets/icons_map/19.png';
import icon20 from '../assets/icons_map/20.png';
import icon21 from '../assets/icons_map/21.png';

const containerStyle = {
  width: '100%',
  height: '600px',
  
};

const center = {
  lat: 47.15818430446797,
  lng: 27.586942081686534,
};

const markers = [
  {
    id: 1,
    name: 'Cinema Trianon',
    position: { lat: 47.16715373846773, lng: 27.579319355922387 },
    icon: icon1,
  },
  {
    id: 2,
    name: 'Palatul Culturii',
    position: { lat: 47.15818430446797, lng: 27.586942081686534 },
    icon: icon2,
  },
  {
    id: 3,
    name: 'Bd. Ștefan cel Mare',
    position: { lat: 47.16246728876976, lng: 27.582740283339813 },
    icon: icon3,
  },
  {
    id: 4,
    name: 'Galeria Dana',
    position: { lat: 47.16572453187226, lng: 27.57483958290271 },
    icon: icon4,
  },
  {
    id: 5,
    name: 'Armeană Open Air',
    position: { lat: 47.163022226129584, lng: 27.588487750956535 },
    icon: icon5,
  },
  {
    id: 6,
    name: 'La Gard',
    position: { lat: 47.17925224936611, lng: 27.56831556834539 },
    icon: icon6,
  },
  {
    id: 7,
    name: 'Galeria Pallady',
    position: { lat: 47.16727609487674, lng: 27.57883354186675 },
    icon: icon7,
  },
  {
    id: 8,
    name: 'Galeria Drossu',
    position: { lat: 47.16713373075511, lng: 27.578839883174346 },
    icon: icon8,
  },
  {
    id: 9,
    name: 'Galeria Tonitza',
    position: { lat: 47.16713373075511, lng: 27.578839883174346 },
    icon: icon9,
  },
  {
    id: 10,
    name: 'Galeria ArtEast',
    position: { lat: 47.17317512529996, lng: 27.561956440576168 },
    icon: icon10,
  },
  {
    id: 11,
    name: 'Palas',
    position: { lat: 47.15823983548444, lng: 27.58890099639253 },
    icon: icon11,
  },
  {
    id: 12,
    name: 'Muzeul Unirii',
    position: { lat: 47.167527849039594, lng: 27.578885913595602 },
    icon: icon12,
  },
  {
    id: 13,
    name: 'Arcada',
    position: { lat: 47.16716367914147, lng: 27.578769205039364 },
    icon: icon13,
  },
  {
    id: 14,
    name: 'Piața Unirii',
    position: { lat: 47.166168628375196, lng: 27.579991573989524 },
    icon: icon14,
  },
  {
    id: 15,
    name: 'Str. Lăpușneanu',
    position: { lat: 47.167663930866794, lng: 27.578629011739284 },
    icon: icon15,
  },
  {
    id: 16,
    name: 'Casa Universitarilor',
    position: { lat: 47.172947179302156, lng: 27.57305499211654 },
    icon: icon16,
  },
  {
    id: 17,
    name: 'Parc Copou',
    position: { lat: 47.17903506938824, lng: 27.567607482903117 },
    icon: icon17,
  },
  {
    id: 18,
    name: 'Esplanada Ateneului',
    position: { lat: 47.16200166314567, lng: 27.606769118806252 },
    icon: icon18,
  },
  {
    id: 19,
    name: 'Bindu',
    position: { lat: 47.16290203852786, lng: 27.58306462651911 },
    icon: icon19,
  },
  {
    id: 20,
    name: 'Grand Hotel Traian',
    position: { lat: 47.16640825847043, lng: 27.57930241810235 },
    icon: icon20,
  },
  {
    id: 21,
    name: 'Ateneul Național din Iași',
    position: { lat: 47.16205605526716, lng: 27.60672581173909 },
    icon: icon21,
  },
];

const Map = () => {
  const [activeMarker, setActiveMarker] = useState(null);

  const { isLoaded } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyARmwGnkCOarSzsjV60fDryAD63caM-yow',
  });

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
     markers.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  return isLoaded ? (
    <GoogleMap
      onLoad={handleOnLoad}
      onClick={() => setActiveMarker(null)}
      mapContainerStyle={containerStyle}
      center={center}
      options={{ styles: [{ elementType: "labels", featureType: "poi", stylers: [{ visibility: "off", }], }], }}
      zoom={15}
      id="map"
    >
      {markers.map(({ id, name, position, icon }) => (
        <Marker
          key={id}
          position={position}
          icon={icon}
          onClick={() => handleActiveMarker(id)}
        >
          {activeMarker === id ? (
            <InfoWindow onCloseClick={() => setActiveMarker(null)}>
              <div>{name}</div>
            </InfoWindow>
          ) : null}
        </Marker>
      ))}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default Map;
