import React from 'react';
import { Link } from 'react-router-dom';
import EnterSiteImage from '../assets/images/EnterSite.png';

const Home = () => {
  return (
      <main className='home'>
        <div className='content'>
          <div className='date'>
            <div className='month'>
              <p>
                <span>19</span> Feb
              </p>
              <span>/</span>
              <p>
                <span>19</span> Mar
              </p>
            </div>
            <div className='vl'></div>
            <div className='year'>
              <p>2023</p>
            </div>
          </div>
          <img src={EnterSiteImage} alt='Luna Sculptorilor Romani' />
          <button>
          <Link to='/main'> ENTER SITE </Link>
          </button>
        </div>
      </main>
  );
};

export default Home;
